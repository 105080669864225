import {http} from './config';

export default {
    
    companyResume: (companyId) => {
        return http.get('api/v1/dashboard/resumoPorCompanhia', {headers: {'company-id': companyId}});
    },
    summaryLotsByAllotment: (allotmentId) => {
        return http.get('api/v1/dashboard/loteamento/resumo/lotes', {headers: {'allotment-id': allotmentId}});
    },
    summaryReservesByAllotment: (allotmentId) => {
        return http.get('api/v1/dashboard/loteamento/resumo/reservas', {headers: {'allotment-id': allotmentId}});
    },
    datasAnual: (companyId, yearInitial, yearFinal) => {
        return http.get(`api/v1/dashboard/progressoMovimentacoesPorCompanhia/${yearInitial}/${yearFinal}`, {headers: {'company-id': companyId}});
    },

    datasAnualReserve: (companyId, allotmentId) => {
        if (allotmentId){
            return http.get(`api/v1/dashboard/progressoReservasPorCompanhia`, {headers: {'company-id': companyId, 'allotment-id': allotmentId}});
        }
        return http.get(`api/v1/dashboard/progressoReservasPorCompanhia`, {headers: {'company-id': companyId}});
    },

    totalStoredByCompany: () => {
        return http.get('api/v1/dashboard/drive/totalArmazenado');
    }
}
