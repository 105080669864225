<template>
    <div>
        <loading-screen :is-loading="isLoading"></loading-screen>
        <page-header :page-title="this.t('APP.TITLE.SYSTEM')"
                     :itenDashboard="financialMovements?.number_total || reservesActive.total ? year : undefined"
                     @selectYear="yearsDash(year)"></page-header>
        <div class="main-container">
            <div class="row gutters">
                <router-link class="col-xl-2 col-lg-4 col-md-6 col-sm-6 col-6" to="/cliente">
                    <div class="info-stats4" @click="$router.push('/cliente')" style="cursor: pointer">
                        <div class="info-icon">
                            <i class="icon-users"></i>
                        </div>
                        <div class="sale-num">
                            <h3>{{ peoples.total }}</h3>
                            <p>{{ this.t('DASHBOARD.PEOPLES') }}</p>
                        </div>
                    </div>
                </router-link>
                <router-link to="/reserva" class="col-xl-2 col-lg-4 col-md-6 col-sm-6 col-6">
                    <div class="info-stats4" @click="$router.push('')" style="cursor: pointer">
                        <div class="info-icon">
                            <i class="icon-lock-open"></i>
                        </div>
                        <div class="sale-num">
                            <h3>{{ reservesActive.total }}</h3>
                            <p>{{ this.t('RESERVE.INDEX_TITLE') }}</p>
                        </div>
                    </div>
                </router-link>
                <router-link to="/loteamento" class="col-xl-2 col-lg-4 col-md-6 col-sm-6 col-6">
                    <div class="info-stats4">
                        <div class="info-icon">
                            <i class="icon-grid"></i>
                        </div>
                        <div class="sale-num">
                            <h3>{{ allotments.total }}</h3>
                            <p>{{ this.t('ALLOTMENT.INDEX_TITLE') }}</p>
                        </div>
                    </div>
                </router-link>
                <router-link to="/lote" class="col-xl-2 col-lg-4 col-md-6 col-sm-6 col-6">
                    <div class="info-stats4">
                        <div class="info-icon">
                            <i class="icon-map-pin"></i>
                        </div>
                        <div class="sale-num">
                            <h3>{{ lots.total }}</h3>
                            <p>{{ this.t('LOTS.INDEX_TITLE') }}</p>
                        </div>
                    </div>
                </router-link>
                <router-link to="/documentos" class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="info-stats4" @click="$router.push('')" style="cursor: pointer">
                        <div class="info-icon">
                            <i class="icon-cloud_upload"></i>
                        </div>
                        <div class="sale-num">
                            <h3>{{ this.formatSize(drive.total) }} /
                                {{ drive.maxStore ? formatMaxStore(drive.maxStore) : '0.00' }}
                                {{ getExtensionSize(drive.maxStore) }} </h3>
                            <p>{{ this.t('DASHBOARD.TOTAL_STORED_AVAILABLE') }}</p>
                        </div>
                    </div>
                </router-link>
            </div>

            <div class="row gutters" v-show="reservesActive?.total">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="card">
                        <div class="p-4 row">
                            <div class="col-12 d-flex flex-row justify-content-between" style="margin-top: -0.5rem"
                                 :style="hasTwoAllotments ? 'margin-bottom: 0.5rem': ''">
                                <h5 class="card-title">{{ this.t('NAV.RESERVE') }}</h5>
                                <Select2 :settings="{ width: '215px', placeholder: 'Filtrar por Loteamento' }"
                                         id="allotments"
                                         v-if="hasTwoAllotments"
                                         name="allotments" :options="[{text: 'Limpar', id: 0}, ...allotmentsStore]"
                                         v-model="allotmentId" @select="renderChartReserve(null, $event)"/>
                            </div>
                            <div class="main-container col-12">
                                <div id="chartReserve" :style="blurLoadingR"></div>
                            </div>
                        </div>
                        <div v-if="!loadingReserve" :class="{ loader: false, fadeout: !loadingReserve}"
                             class="d-flex justify-content-center align-items-center">
                            <div class="d-flex justify-content-center align-items-center">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                        <div class="card-body pt-0">
                            <div id="visitorsReserve"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Dashboard from "@/services/Dashboard";
import * as ApexCharts from "apexcharts";
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";
import {mapState} from "vuex";
import LoadingScreen from "@/components/layouts/loadScreenComponent";
import PageHeader from "@/components/layouts/pageHeader";
import moment from "moment";
import permission from "../../components/permission";
import Select2 from 'vue3-select2-component';

export default {
    name: 'Home',
    mixins: [permission],
    components: {
        LoadingScreen,
        PageHeader,
        Select2
    },

    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },

    computed: {
        ...mapState({
            isLoading: 'isLoading',
            hasTwoAllotments: 'hastwoAllotments',
            allotmentLoad: 'allotmentLoad',
            allotmentsStore: 'allotments',
        }),
        blurLoading() {
            if (!this.loadingMovements) {
                return '-webkit-filter: blur(5px)';
            }
            return '';
        },
        blurLoadingR() {
            if (!this.loadingReserve) {
                return '-webkit-filter: blur(5px)';
            }
            return '';
        }
    },
    beforeRouteLeave(to, from, next) {
        if (!this.chart && !this.chartReserve) {
            return next();
        } else {
            this.chartReserve.destroy();
            this.chartOptions = {};
            this.chartOptionsReserve = {};
            this.series = [];
            this.seriesReserve = [];
            return next();
        }
    },

    data: function () {
        return {
            totalClients: 0,
            peoples: {},
            drive: {
                total: null,
                maxStore: null
            },
            lots: {},
            financialMovements: {},
            salesStatus: {},
            chartOptions: {},
            chartOptionsReserve: {},
            series: [],
            seriesReserve: [],
            allotmentId: null,
            allotmentOptions: null,
            noneData: false,
            year: {
                yearInitial: '2022',
                yearFinal: '2022',
                years: [],
            },
            yearInitial: '2022',
            yearFinal: '2022',
            years: [],
            chart: null,
            chartReserve: null,
            loadingMovements: true,
            loadingReserve: true,
            allotments: {},
            reservesActive: {}
        }
    },

    mounted() {
        if (this.validatePermissions('Dashboard:resume')) {
            this.renderChartReserve();
            this.getTotalDriveStored();
            this.awaitSelect();
        }
    },

    methods: {
        yearsDash(iten) {
            this.yearInitial = iten.yearInitial;
            this.yearFinal = iten.yearFinal;
            this.renderChart();
        },
        getExtensionSize(size) {
            if (size < 1) {
                return 'MB';
            } else return 'GB';
        },
        formatMaxStore(value) {

            if (value < 1) {
                return (value * 1000).toFixed(1);
            }
            return value
        },
        async awaitSelect() {
            let companyId = JSON.parse(localStorage.getItem('companies'))
            let companySelected = localStorage.getItem('companyId');

            if (companyId.length === 1) {
                let uniqueCompanyId = companyId[0].id
                await this.getResume(uniqueCompanyId);
            } else if (!companySelected) {
                console.log('waiting...');
            } else {
                await this.getResume(companySelected);
            }
        },
        getYears() {
            let yearActual = moment().year();
            for (let i = (yearActual - 60); i <= (yearActual + 10); i++) {
                let arr = [];
                let iString = i.toString();
                arr['text'] = iString;
                arr['id'] = iString;

                this.years.unshift(arr);
                this.year.years.unshift(arr);
            }

            this.year.years.forEach((item, key) => {
                if (item.text === yearActual.toString()) {
                    this.year.yearInitial = this.year.years[key].id;
                    this.year.yearFinal = this.year.years[key].id;
                    return key;
                }
            })
            this.years.forEach((item, key) => {
                if (item.text === yearActual.toString()) {
                    this.yearInitial = this.years[key].id;
                    this.yearFinal = this.years[key].id;
                    return key;
                }
            })
        },
        async getTotalDriveStored() {
            Dashboard.totalStoredByCompany().then(resp => {
                this.drive = resp.data
            });
        },
        formatSize(fileSize) {
            if (!fileSize) return '0.0';
            if (fileSize < 1000) return `${fileSize} Bytes`;
            if (fileSize > 1000 && fileSize < 1000000) return `${(fileSize / 1000).toFixed()} KB`;
            if (fileSize >= 1000000 && fileSize < 1000000000) return `${(fileSize / 1000000).toFixed(2)} MB`;
            if (fileSize >= 1000000000) return `${(fileSize / 1000000000).toFixed(2)} GB`;
        },
        async getResume(companyId) {
            await Dashboard.companyResume(companyId).then((resp) => {
                this.financialMovements.number_total = 0;
                this.salesStatus.total = 0;
                this.peoples = resp.data.people;
                this.lots = resp.data.lots;
                this.salesStatus = resp.data.sale_status;
                this.financialMovements = resp.data.financial_moviments;
                this.allotments = resp.data.allotments;
                this.reservesActive = resp.data.reserves_active;

            }).catch((err) => {
                this.errorMsg(err);
            })
        },

        async renderChartReserve(companyId, allotment = null) {
            if (this.chartReserve) {
                this.chartReserve.destroy();
                this.chartOptions = {};
                this.chartOptionsReserve = {};
                this.series = [];
                this.seriesReserve = [];
            }
            this.loadingReserve = false;
            if (!companyId) {
                companyId = localStorage.getItem('companyId');
            }
            if (allotment) this.allotmentId = allotment.id;
            Dashboard.datasAnualReserve(companyId, this.allotmentId).then(resp => {
                if (this.chartReserve) {
                    this.chartReserve.destroy();
                }
                let result = resp.data
                this.seriesReserve = [];

                this.seriesReserve.push({
                    name: result.name1,
                    data: result.reserves
                });

                this.seriesReserve.push({
                    name: result.name2,
                    data: result.reservesConfirmed
                });

                this.seriesReserve.push({
                    name: result.name3,
                    data: result.reservesExpiredAndInterrupted
                });

                this.seriesReserve.push({
                    name: result.name4,
                    data: result.reservesInProgress
                });
                this.seriesReserve.push({
                    name: result.name5,
                    data: result.reservesSaleCanceled
                });

                this.chartOptionsReserve = {
                    chart: {
                        height: '280',
                        type: 'area',
                        width: '100%',
                        defaultLocale: 'pt',
                        locales: [{
                            name: 'pt',
                            options: {
                                toolbar: {
                                    download: 'Download SVG',
                                    selection: 'Selecionar',
                                    selectionZoom: 'Selecionar Zoom',
                                    zoomIn: 'Aumentar Zoom',
                                    zoomOut: 'Reduzir Zoom',
                                    pan: 'Panorama',
                                    reset: 'Resetar Zoom',
                                }
                            }
                        }]
                    },
                    noData: {
                        text: 'Nenhum dado para ser carregado',
                        align: 'center',
                        verticalAlign: 'middle',
                    },
                    series: this.seriesReserve,
                    xaxis: {
                        convertedCatToNumeric: false,
                        categories: result.categories
                    },
                    colors: ['#0E5E4E', '#0FBC9A', '#0A4a23', '#001000', '#008822'],
                }


                let elChartReserve = document.getElementById('chartReserve');
                this.chartReserve = new ApexCharts(elChartReserve, this.chartOptionsReserve,);

                this.chartReserve.render();
                this.loadingReserve = true
            }).catch((err) => {
                this.errorMsg(err);
                this.loadingReserve = true
            });
        },
        async renderChart(companyId) {
            this.loadingMovements = false;
            if (!companyId) {
                companyId = localStorage.getItem('companyId');
            }

            Dashboard.datasAnual(companyId, this.yearInitial, this.yearFinal).then((resp) => {
                if (this.chart) {
                    this.chart.destroy();
                }
                let result = resp.data
                let moviments = {};
                let payments = {};

                this.series = [];
                moviments.data = result.financialMovements;
                moviments.name = result.name1;
                this.series.push(moviments);

                payments.data = result.payments;
                payments.name = result.name2;
                this.series.push(payments);

                this.chartOptions = {
                    chart: {
                        type: 'area',
                        width: '100%',
                        height: '250px',
                    },
                    noData: {
                        text: 'Nenhum dado para ser carregado',
                        align: 'center',
                        verticalAlign: 'middle',
                    },
                    series: this.series,
                    xaxis: {
                        convertedCatToNumeric: false,
                        categories: result.categories
                    },
                    colors: ['#0e5e4e', '#0FBC9A', '#23A88D'],
                }

                let elChart = document.getElementById('chart');
                this.chart = new ApexCharts(elChart, this.chartOptions);

                this.chart.render();
                this.loadingMovements = true
            }).catch((err) => {
                this.errorMsg(err);
                this.loadingMovements = true
            })
        },

        errorMsg(error) {
            const erro = error.response.data.validations.errors;

            if (erro.sql || !erro) {
                this.toast.error(error.response.data.validations.message, {
                    timeout: false,
                });
            } else {
                for (let column in erro) {
                    for (let msg in erro[column]) {
                        this.toast.error(erro[column][msg], {timeout: false});
                    }
                }
            }
        },
    },
}

</script>
